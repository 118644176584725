@import '../lib/palette';

@keyframes shimmer {
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}

.skeleton {
  & .column {
    position: relative;
    overflow: hidden;
  }
}

@mixin skeletonAnimation() {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
      90deg,
      rgba($color-primary-dark, 0) 0,
      rgba($color-primary-dark, 0.2) 20%,
      rgba($color-primary-dark, 0.5) 60%,
      rgba($color-primary-dark, 0)
  );
  animation: shimmer 2s infinite;
  z-index: 4;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

@mixin skeletonAnimationLight() {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  transform: translateX(-100%);
  background-image: linear-gradient(
      90deg,
      rgba($color-dark, 0) 0,
      rgba($color-dark, 0.2) 20%,
      rgba($color-dark, 0.5) 60%,
      rgba($color-dark, 0)
  );
  animation: shimmer 2s infinite;
  z-index: 3;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

@mixin skeletonBackground($width) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: $width;
  height: 100%;
  background-color: $color-primary-dark-alter;
  z-index: 5;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

@mixin skeletonBackgroundLight($width) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: $width;
  height: 100%;
  background-color: $color-light;
  z-index: 2;

  @include breakpoint(medium down) {
    width: 100%;
  }
}

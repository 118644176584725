@import '../lib/main';
@import './font';
@import './normalize';
@import './form-grid';
@import './grid';
@import './containers';
@import './modal';
@import './text';
@import './layout';

$body-antialiased: true !default;

html {
  box-sizing: border-box;
  font-size: $base-font-size;
}

html,
body {
  @include propertyWithInset('margin-left', 0, 'left');
  @include propertyWithInset('margin-right', 0, 'right');
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
  box-sizing: inherit;
}

// Default body styles
body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  background: #1f145c;
  font-family: $body-font-family;
  line-height: $global-lineheight;
  color: $body-font-color;
  font-weight: normal;

  @if $body-antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

img {
  // Get rid of gap under images by making them display: inline-block; by default
  display: inline-block;
  vertical-align: middle;

  // Grid defaults to get images and embeds to work properly
  max-width: 100%;
  height: auto;
}

// Make sure textarea takes on height automatically
textarea {
  height: auto;
  min-height: 50px;
  border-radius: $global-radius;
}

input {
  background: transparent;
  border: none;
  outline: none;
  min-width: 0;
}

div[contenteditable] {
  appearance: none;
  outline: none;
  cursor: text;
}

// Make select elements are 100% width by default
select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

[inv='true'] {
  visibility: hidden;
}

// Reset <button> styles created by most browsers
button {
  outline: 0;
  padding: 0;
  color: inherit;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  cursor: pointer;
}

// Prevent text overflow on pre
pre {
  overflow: auto;
}

p {
  margin: 0;
}

// Make reset inherit font-family instead of settings sans-serif
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

cdk-virtual-scroll-viewport {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.cdk-virtual-scroll-content-wrapper {
  overflow-x: hidden !important;
  max-width: 100%;
}

.size-ref {
  position: absolute;
  visibility: hidden;
  width: 100%;
}

// text colors and font-sizes line-height etc...

// font sizes
.font-tn {
  font-size: rem(10);
}

.font-sm {
  font-size: rem(12);
}

.font-md {
  font-size: rem(14);
}

.font-lg {
  font-size: rem(16);
}

.font-hg {
  font-size: rem(18);
}

.font-xhg {
  font-size: rem(22);
}

.font-xxhg {
  font-size: rem(28);
}

.font-xxxhg {
  font-size: rem(32);
}

.font-ico {
  font-size: rem(24);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// headers
h1,
h2,
h3,
h4,
h5,
h6,
.header-h1,
.header-h2,
.header-h3,
.header-h4,
.header-h5,
.header-h6 {
  font-weight: 600;
  color: $color-text-bold;

  &.color-primary {
    color: $color-primary;
  }

  & > .icon {
    margin-right: rem(6);
  }
}

h1,
.header-h1 {
  font-size: rem(30);
  line-height: rem(30);
}

h2,
.header-h2 {
  font-size: rem(26);
  line-height: rem(30);
}

h3,
.header-h3 {
  font-size: rem(22);
  line-height: rem(25);
}

h5,
.header-h5 {
  font-size: rem(18);
  line-height: rem(20);
}

// font weights
.weight-semibold {
  font-weight: 600;
}

.weight-medium {
  font-weight: 500;
}

.weight-bold {
  font-weight: 700;
}

.weight-normal {
  font-weight: 400;
}

// text colors
.color-error {
  color: $color-error;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #222;
}

.color-success {
  color: $color-success;
}

.color-warn {
  color: $color-warn;
}

.color-main {
  color: $color-primary;
}

.color-primary {
  color: $color-text-primary;
}

.color-primary-light {
  color: $color-text-primary-light;
}

.color-primary-dark {
  color: $color-text-primary-dark;
}

.color-info {
  color: $color-secondary-icon;
}

.color-icon-secondary {
  color: $color-secondary-icon;
}

.color-txt {
  color: $color-text-default;
}

.color-txt-bold {
  color: $color-text-bold;
}

.color-txt-secondary {
  color: $color-text-secondary;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.link {
  cursor: pointer;
}

.link-primary {
  color: $color-primary;
  text-decoration: underline;
}

.cursor-pointer {
  cursor: pointer;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-text-wrap {
  white-space: nowrap;
}

.line-height-md {
  line-height: rem(14);
}

.background-warn {
  background: $color-warn;
}

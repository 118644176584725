.data-container {
  display: flex;
  position: relative;
  width: 100%;
  padding: 10px;
  background: $color-container-light;
  border-radius: 8px;

  @include breakpoint(medium) {
    padding: $dc-tablet-padding;
  }

  @include breakpoint(large) {
    padding: $dc-padding;
  }

  &.faded {
    &::after {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.65);
    transition: opacity 300ms;
  }

  &.dark {
    background: $color-container-dark;
  }

  &.transparent {
    background: transparent;
  }

  & .container-header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 38px;
  }

  & .container-header-2 {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  & .container-hint {
    margin-bottom: 25px;
  }

  &.container-column {
    justify-content: center;
    flex-direction: column;
  }

  &.container-items-center {
    align-items: center;
  }
}

.data-divider {
  width: 100%;
  height: 1px;
  background-color: $color-light;
  margin: rem(38) 0;

  &::after {
    content: attr(data-text);
    background-color: $color-text-alter;
    padding: 6px rem(20);
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

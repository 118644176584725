/* You can add global styles to this file, and also import other style files */

@import './lib/main';
@import './core/main';

// cdk
@import '@angular/cdk/overlay-prebuilt.css';

@include ripple;

:root {
  --header-height: 62px;
  --header-height-sm: 56px;
}

body.modal-active,
body.cdk-anchor-active,
body.fixed-card-active {
  overflow: hidden;
}

body.overlay-on {
  overflow: hidden;

  &::before {
    position: fixed;
    z-index: z('overlay');
    opacity: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

body.cart-overlay {
  overflow: hidden;

  &::before {
    position: fixed;
    z-index: z('modal') - 2;
    opacity: 1;
    top: $header-height;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

// overlay for cdk select
body.cdk-overlay {
  overflow: hidden;

  &::after {
    position: fixed;
    z-index: z('modal') + 1;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

body.overlay-off {
  &::before {
    opacity: 0;
  }
}

body.cdk-overlay-off {
  &::after {
    opacity: 0;
  }
}

body {
  &::before,
  &::after {
    content: '';
    background-color: rgba(0, 0, 0, 0.55);
    opacity: 0;
    transition: opacity 0.3s;
  }
}

.dc-scroll-light {
  scrollbar-color: #845ff7 #fff;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    cursor: pointer;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background: #fff;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-corner {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: #845ff7;
    transition: background 0.3s;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: darken(#845ff7, 4%);
  }
}

.mapboxgl-popup-content {
  border-radius: $global-radius + 4;
}

// hide reCAPTCHA badge
.rc-anchor-normal-footer {
  display: none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

// Temporary solution to move chat buttons (mobile/desktop)

#jvlabelWrap {
  right: 80px !important;

  @include breakpoint(medium) {
    right: 50px !important;
  }
}

jdiv {
  z-index: z('modal') - 2 !important;
}

.__jivoMobileButton {
  z-index: 30 !important;

  & > jdiv {
    transform: scale(0.75) translate(26px, 26px) !important;
  }

  & > jdiv > jdiv > jdiv {
    background-image: url(/assets/svg/chat.svg) !important;
  }
}

@include breakpoint(small only) {
  body.page-map {
    .__jivoMobileButton {
      display: none;
    }
  }
}

body.streaming {
  #jvlabelWrap {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: default;
  }

  .__jivoMobileButton {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    cursor: default;
  }
}

.cdk-global-scrollblock .header::before {
  opacity: 1;
  backdrop-filter: blur(5px);
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

@import './palette';

// global variables
$body-font-family: 'Montserrat', sans-serif;
$secondary-font-family: 'Open Sans', sans-serif;
$base-font-size: 14px;
$body-background: $color-body-background;
$body-font-color: $color-text-default;

$global-radius: 4px;
$global-lineheight: 16px;
$global-shadow: 5px 10px 15px 0 rgb(9 14 19 / 26%);
$global-width: rem(1800);
$global-width-sm: rem(520);

$header-height: var(--header-height);
$header-height-sm: var(--header-height-sm);
$container-padding: rem(16) rem(10) rem(20);

// layers
$z-layers: (
  'modal': 100,
  'menu': 90,
  'overlay': 85,
  'dropdown': 40
);

// -----------------
// containers
$dc-mobile-padding: rem(10);
$dc-tablet-padding: rem(20);
$dc-padding: rem(40);

// buttons
$btn-height: rem(50);
$btn-height-md: rem(40);
$btn-height-sm: rem(32);
$btn-height-lg: rem(58);
$btn-padding-h: rem(16);
$btn-padding-sm-h: rem(8);
$btn-font-size: $base-font-size;
$btn-font-size-sm: 12px;

$btn-color-primary: $color-primary;
$btn-color-primary-dark: $color-primary-dark;
$btn-color-primary-text: $color-text-alter;
$btn-color-primary-text-alter: $color-primary;

$btn-color-accent: $color-accent;
$btn-color-accent-text: $color-text-alter;

$btn-color-error: $color-error;
$btn-color-error-text: $color-text-alter;

$btn-color-basic: $color-text-alter;
$btn-color-basic-border: $color-light;
$btn-color-basic-text: $color-text-bold;

// round
$btnr-size: rem(38);
$btnr-size-md: rem(30);
$btnr-size-sm: rem(24);
$btnr-font-size: $base-font-size;
$btnr-font-size-md: $base-font-size;
$btnr-color-basic: $color-secondary;
$btnr-color-clear: $color-text-alter;
$btnr-color-basic-icon: $color-secondary-icon;

// toggle
$tgl-color-text: $color-text-default;
$tgl-color-thumb: $color-text-alter;
$tgl-color-on: $color-primary;
$tgl-color-off: $color-light;

// checkbox
$cbox-icon-size: rem(22);
$cbox-color-text: $color-text-default;
$cbox-color-on: $color-primary;
$cbox-color-off: $color-light;
$cbox-color-check: $color-text-alter;

// -----------------
// input
$inp-font-size: $base-font-size;
$inp-color-text: $color-text-bold;
$inp-color-icon: $color-text-bold;
$inp-color-ph: $color-text-light;
$inp-height: rem(50);
$inp-height-md: rem(38);
$inp-icon-width: rem(36);
$inp-padding-v: rem(12);
$inp-border-width: rem(1);
$inp-color-border: $color-light;
$inp-color-border-hover: $color-dark;
$inp-color-border-focus: $color-primary;
$inp-color-base: $color-text-alter;
$inp-color-error: $color-error;
$inp-color-error-l: $color-error-l;

// defines specified property with addition of status bar size
@mixin propertyWithInset($property: 'height', $size: 0, $insetType: 'top', $i: '') {
  #{$property}: calc(#{$size}px) #{$i};
  #{$property}: calc(#{$size}px + var(--cvd-inset-#{$insetType}, 0px)) #{$i};
  #{$property}: calc(#{$size}px + constant(safe-area-inset-#{$insetType}, 0px)) #{$i};
  #{$property}: calc(#{$size}px + env(safe-area-inset-#{$insetType}, 0px)) #{$i};
}

@mixin propertyWithoutInset($property: 'height', $size: 0, $insetType: 'both') {
  #{$property}: calc(#{$size}px);

  // we can add --browser-inset- to define browser address bar height, so the view can be displayed full screen
  // we can use now --cvd-inset- to define android status bar height
  @if $insetType== 'both' {
    #{$property}: calc(#{$size} - var(--cvd-inset-top, 0px) - var(--cvd-inset-bottom, 0px));
    #{$property}: calc(
      #{$size} - constant(safe-area-inset-top, 0px) - constant(safe-area-inset-bottom, 0px)
    );
    #{$property}: calc(#{$size} - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px));
  } @else if $insetType != 'none' {
    #{$property}: calc(#{$size} - var(--cvd-inset-#{$insetType}, 0px));
    #{$property}: calc(#{$size} - constant(safe-area-inset-#{$insetType}, 0px));
    #{$property}: calc(#{$size} - env(safe-area-inset-#{$insetType}, 0px));
  }
}

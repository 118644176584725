.modal-container {
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  background: $color-container-light;
  border-radius: $global-radius + 4px;
  padding: 0 20px 30px 20px;

  @include breakpoint(medium) {
    padding: 0 40px 30px 40px;
  }

  &.faded {
    &::after {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.65);
    transition: opacity 300ms;
  }

  .modal-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    margin-bottom: 16px;
  }

  .modal-title-primary {
    display: flex;
    align-items: center;
    color: $color-text-primary;
    font-weight: 500;
    margin-top: 28px;
    margin-bottom: 16px;
  }

  .modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    width: 100%;
  }

  .modal-prompt {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: 16px;
  }

  .app-scroll {
    width: calc((100% + #{$dc-padding * 2}));
    margin-left: -$dc-padding;

    .modal-main {
      padding: 12px $dc-padding;
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }

  .modal-footer {
    display: flex;
    position: relative;
    justify-content: space-between;
    margin-top: 20px;

    button {
      flex: 1;
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        width: 38px;
        height: 38px;
        flex: none;
      }
    }
  }
}

.modal-element.modal-dark {
  .modal-container {
    background-color: $color-modal-dark;
  }
}

.modal-element.modal-black {
  .modal-container {
    background-color: $color-modal-black;
  }
}

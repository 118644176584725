@use 'sass:math';

// how to use xy-grid mixin's manually see https://get.foundation/sites/docs/xy-grid.html
.form-grid {
  $gutters: rem(22);
  $gutters-v: rem(28);
  @include xy-grid(horizontal, true);
  @include xy-gutters($negative: true, $gutters: $gutters, $gutter-position: left right);
  @include xy-gutters($negative: true, $gutters: $gutters-v, $gutter-position: top bottom);

  & > .cell-tn {
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: top bottom);
    @include xy-cell(
      $size: 6,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );

    @include breakpoint(medium) {
      @include xy-cell(
        $size: math.div(12, 4),
        $gutters: $gutters,
        $gutter-position: left right,
        $output: (
          size gutters
        )
      );
    }
  }

  & > .cell-sm {
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: top bottom);
    @include xy-cell(
      $size: 6,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );
  }

  & > .cell {
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: top bottom);
    @include xy-cell(
      $size: 12,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );

    @include breakpoint(medium) {
      @include xy-cell(
        $size: 6,
        $gutters: $gutters,
        $gutter-position: left right,
        $output: (
          size gutters
        )
      );
    }
  }

  & > .cell-br {
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: top bottom);
    @include xy-cell(
      $size: 12,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );

    @include breakpoint(medium) {
      @include xy-cell(
        $size: 6,
        $gutters: $gutters,
        $gutter-position: left right,
        $output: (
          size gutters
        )
      );

      margin-right: 50%;
    }
  }

  & > .cell-lg {
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: top bottom);
    @include xy-cell(
      $size: 12,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );
  }

  & > .cell-sub {
    @include xy-cell(
      $size: 12,
      $gutters: $gutters,
      $gutter-position: left right,
      $output: (
        size gutters
      )
    );
    @include xy-cell-gutters($gutters: $gutters-v + rem(16), $gutter-position: top);
    @include xy-cell-gutters($gutters: $gutters-v, $gutter-position: bottom);
  }
}

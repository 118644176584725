.width-100 {
  width: 100%;
}

.width-50 {
  width: 50%;
}

.height-100 {
  height: 100%;
}

.position-relative {
  position: relative;
}

.display-flex {
  display: flex;
}

.display-none-i {
  display: none !important;
}

.display-inline-block {
  display: inline-block;
}

.display-flex-column {
  display: flex;
  flex-flow: column;
}

.display-flex-wrap {
  display: flex;
  flex-flow: wrap;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.flex-4 {
  flex: 4;
}

.flex-5 {
  flex: 5;
}

// * Margins */

.margin-right-auto {
  margin-right: auto;
}

.margin-left-auto {
  margin-left: auto;
}

.margin-top-auto {
  margin-top: auto;
}

// margin extra huge and extra extra huge (50px, 56px)
.margin-bottom-xhg {
  margin-bottom: rem(50);
}

.margin-bottom-xxhg {
  margin-bottom: rem(56);
}

.margin-top-xhg {
  margin-top: rem(50);
}

.margin-top-xxhg {
  margin-top: rem(56);
}

// margin huge (30px)
.margin-bottom-hg {
  margin-bottom: rem(30);
}

.margin-top-hg {
  margin-top: rem(30);
}

.margin-left-hg {
  margin-left: rem(30);
}

.margin-right-hg {
  margin-right: rem(30);
}

// margin large (20px)
.margin-top-lg {
  margin-top: rem(20);
}

.margin-bottom-lg {
  margin-bottom: rem(20);
}

.margin-left-lg {
  margin-left: rem(20);
}

.margin-right-lg {
  margin-right: rem(20);
}

// margin 16px
.margin-top-md {
  margin-top: rem(16);
}

.margin-bottom-md {
  margin-bottom: rem(16);
}

.margin-left-md {
  margin-left: rem(16);
}

.margin-right-md {
  margin-right: rem(16);
}

// margin small (12px)
.margin-top-sm {
  margin-top: rem(12);
}

.margin-bottom-sm {
  margin-bottom: rem(12);
}

.margin-left-sm {
  margin-left: rem(12);
}

.margin-right-sm {
  margin-right: rem(12);
}

// margin (10px)
.margin-top {
  margin-top: rem(10);
}

.margin-bottom {
  margin-bottom: rem(10);
}

.margin-left {
  margin-left: rem(10);
}

.margin-right {
  margin-right: rem(10);
}

// margin (4px)
.margin-top-tn {
  margin-top: rem(4);
}

.margin-bottom-tn {
  margin-bottom: rem(4);
}

.margin-left-tn {
  margin-left: rem(4);
}

.margin-right-tn {
  margin-right: rem(4);
}

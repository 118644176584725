@import 'variables';

@mixin var($property, $var, $value, $i: '') {
  #{$property}: $value #{$i};
  #{$property}: var(--#{$var}) #{$i};
}

@mixin hover {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

@mixin canHover {
  @media (hover: hover) and (pointer: fine) {
    @content;
  }
}

@mixin singleEllipsis() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin multilineEllipsis($lineHeight: 18px, $lineCount: 2) {
  overflow: hidden;
  white-space: initial;
  text-overflow: initial;
  // webkit multiline ellipsis
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lineCount;
  // opera multiline ellipsis
  text-overflow: -o-ellipsis-lastline;
  // for all browsers we set max height and line height at least it will work event if above are not
  max-height: $lineHeight * $lineCount - 4px;
}

@mixin fontWeight($weight) {
  @supports (-webkit-text-stroke-width: $weight) {
    -webkit-text-stroke-width: $weight;
  }
  // fallback for older browsers: tiny shadows at right & left of the text
  // * (replace both instances of "black" with the font color)
  @supports not (-webkit-text-stroke-width: $weight) {
    text-shadow: -0.05ex 0, 0.05ex 0;
  }
}

@mixin ripple() {
  .mat-ripple {
    overflow: hidden;
    position: relative;
    z-index: 1;
    // Promote containers that have ripples to a new layer. We want to target `:not(:empty)`,
    // because we don't want all ripple containers to have their own layer since they're used in a
    // lot of places and the layer is only relevant while animating. Note that ideally we'd use
    // the `contain` property here (see #13175), because `:empty` can be broken by having extra
    // text inside the element, but it isn't very well supported yet.
    &:not(:empty) {
      transform: translateZ(0);
    }
  }

  .mat-ripple.mat-ripple-unbounded {
    overflow: visible;
  }

  .mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;

    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
  }
}

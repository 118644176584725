// primary
$color-primary: #8460f8;
$color-primary-dark: #4e0da2;
$color-primary-dark-alter: #300d82;
$color-primary-alter: #4f4085;
$color-primary-light: #a98fff;
$color-primary-extra-light: #c7b6ff;
$color-primary-a: rgba(#8460f8, 8%);
//secondary
$color-secondary: #e4e5eb;
$color-secondary-icon: #878e9f;

$color-accent: #ea8b46;
$color-accent-text: #ea8b46;

//success
$color-success: #20cd80;

//warn
$color-warn: #f5be2f;
$color-warn-light: #fadf97;

// info
$color-info: $color-primary;

//error
$color-error: #f66132;
$color-error-l: lighten(#f66132, 5%);

//other
$color-lines: rgba(255, 255, 255, 0.2);
$color-icon-light: #979797;
$color-light: #dddddd;
$color-dark: #444444;
$color-withdrawal: #be05ff;
$color-discord: #583df2;

// special
$color-plan1: #f5be2f; // for reference basic
$color-plan2: #f66132; // for reference standard
$color-plan3: #1cc0d4; // for reference premium
$color-plan4: #5e2ff5; // for reference unique
$color-plan5: #ee6a99; // for reference unique
$color-body-background: #f6f6f6;
$color-container-dark: #130d62;
$color-container-dark-alter: #160f72;
$color-input-background-dark: #1c1385;
$color-container-light: #ffffff;

$color-modal-dark: $color-primary-dark;
$color-modal-black: #130d62;

// text
$color-text-default: #666;
$color-text-secondary: rgba(17, 15, 63, 0.5);
$color-text-bold: #222;
$color-text-light: #999;
$color-text-alter: #fff;
$color-text-primary: $color-primary;
$color-text-primary-light: #a276ff;
$color-text-primary-dark: #4e0ea2;

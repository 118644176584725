// FILE ONLY FOR FONT IMPORTS
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Politica ExtraBold';
  src: url('/assets/fonts/Politica-ExtraBold.eot');
  src: url('/assets/fonts/Politica-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Politica-ExtraBold.woff') format('woff'),
    url('/assets/fonts/Politica-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
